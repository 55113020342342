import React from 'react'
import { Link } from 'react-router-dom';
import StarRating from '../StarRating/StarRating';
import classes from './thumbnails.module.css';

export default function Thumbnails({ offerWalls }) {
  return (
    <ul className={classes.list}>

<li key={'adscendmedia'}>
            <Link to={`/offerwall/adscend`}>
                <img
              className={classes.image}
              src={`adscend.jpeg`}
              alt={"Adscend Media"}
            />
          <div className={classes.content}>
            <span className={classes.popular}></span>
            <div className={classes.stars}>
                <StarRating stars={5} />
            </div>
          </div>
          </Link>
  </li>

  <li key={'lootably'}>
            <Link to={`/offerwall/lootably`}>
                <img
              className={classes.image}
              src={`lootably.png`}
              alt={"Lootably"}
            />
          <div className={classes.content}>
            <span className={classes.popular}></span>
            <div className={classes.stars}>
                <StarRating stars={5} />
            </div>
          </div>
          </Link>
  </li>

    <li key={'mychip'}>
            <Link to={`/offerwall/mychips`}>
                <img
              className={classes.image}
              src={`mychipslogo2.png`}
              alt={"MyChips"}
            />
          <div className={classes.content}>
            <span className={classes.popular}></span>
            <div className={classes.stars}>
                <StarRating stars={5} />
            </div>
          </div>
          </Link>
    </li>
  
       <li key={'revlum'}>
            <Link to={`/offerwall/revlum`}>
                <img
              className={classes.image}
              src={`revlumlogo.svg`}
              alt={"Revlum"}
            />
          <div className={classes.content}>
            <span className={classes.popular}></span>
            <div className={classes.stars}>
                <StarRating stars={4} />
            </div>
          </div>
          </Link>
  </li> 
        <li key={'wannads'}>
            <Link to={`/offerwall/wannads`}>
                <img
              className={classes.image}
              src={`wannads-wide.png`}
              alt={"Wannads"}
            />
          <div className={classes.content}>
            <span className={classes.popular}></span>
            <div className={classes.stars}>
                <StarRating stars={4} />
            </div>
          </div>
          </Link>
        </li>
    </ul>
  );
}
