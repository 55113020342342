import classes from './offersOnly.module.css';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import StarRating from '../../components/StarRating/StarRating';
import Button from '../../components/Button/Button';
import WallOffer from '../../components/WallOffer/WallOffer';
import jsonp from 'jsonp';
import fetchJsonp from 'fetch-jsonp';
import { getRevlumOffers } from '../../services/revlumService';
import { getWannadsOffers } from '../../services/wannadsService';
import { getUserGeo } from '../../services/userGeoService';
import { getCpaLeadOffers } from '../../services/cpaLeadService';

const OffersOnly = () => {
    const { user } = useAuth();
    const [ip, setIP] = useState("");
    const [surveys, setSurveys] = useState([]);
    const [offers, setOffers] = useState([]);
    const [wannadsOffers, setWannadsOffers] = useState([]);
    const [revlumOffers, setRevlumOffers] = useState([]);
    const [country, setCountry] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchIPAndCountry = async () => {
            try {
                const ipRes = await axios.get("https://api.ipify.org/?format=json");
                const fetchedIP = ipRes.data.ip;
                setIP(fetchedIP);

                const geoRes = await getUserGeo(fetchedIP);
                const fetchedCountry = geoRes.country_code2;
                setCountry(fetchedCountry);

                console.log("User IP is " + fetchedIP);
                console.log("User country is " + fetchedCountry);
            } catch (error) {
                setError(error.message ? error.message : JSON.stringify(error));
                setLoading(false);
            }
        };

        fetchIPAndCountry();
    }, []);

    useEffect(() => {
        const fetchOffers = async () => {
            if (user.id && ip && country) {
                try {

                    const [wannadsData, revlumData, cpaLeadData] = await Promise.all([
                        getWannadsOffers(user.id, ip),
                        getRevlumOffers(country),
                        getCpaLeadOffers(user.id)
                    ]);
                    

                    const transformWannadsOffers = wannadsData.map(offer => ({
                        id: offer.offer_id,
                        title: offer.name,
                        description: offer.requirements,
                        imageUrl: offer.img_url,
                        link: offer.offer_url,
                        reward: offer.virtual_currency_value
                    }));

                    const transformRevlumOffers = revlumData.map(offer => {
                        let url = new URL(offer.url);
                        url.searchParams.set('userId', user.id);
                        url.searchParams.delete('sub1');

                        return {
                            id: offer.offerID,
                            title: offer.title,
                            description: offer.description,
                            imageUrl: offer.image,
                            link: url.toString(),
                            reward: Number(offer.payout) * 100 * 0.50 
                        }
                    });

                    const transformCpaLeadOffers = cpaLeadData.map(offer => {
                        return {
                            id: offer.id,
                            title: offer.title,
                            description: offer.conversion,
                            imageUrl: offer.creatives.url,
                            link: offer.link,
                            reward: Number(offer.amount) * 100 * 0.50 
                        }
                    });
                
                const allOffers = [...transformWannadsOffers, ...transformRevlumOffers, ...transformCpaLeadOffers];
                const uniqueOffers = Array.from(new Set(allOffers.map(offer => offer.title)))
                                    .map(title => allOffers.find(offer => offer.title === title));
                setOffers(uniqueOffers);
                } catch (error) {
                    setError(error.message ? error.message : JSON.stringify(error));
                } finally {
                    setLoading(false);
                }
            }
            };
            fetchOffers();
    }, [user.id, ip, country]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
            <div className={classes.categories}>
        <Button text="Explore" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/tasks'; }}/>
        <Button text="Surveys" color="white" backgroundColor="black" width="8rem" height="2rem" fontSize="1rem" onClick={() => { window.location.href = '/surveys'; }}/>
        <Button text="Offers" color="black" backgroundColor="white" width="8rem" height="2rem" fontSize="1rem"/>
        </div>
    <h2 className={classes.sponsoredOffersHeading}>Available Offers</h2>
    <ul className={classes.list}>
    {offers && offers.map(offer => (
      <li key={offer.id} className={classes.item}>
        <WallOffer
          title_text={offer.title}
          center_text={offer.description}
          image_game={offer.imageUrl}
          image_android={'/android.png'}
          image_Trophy={'/trophy.png'}

          link={offer.link}
          reward_payout={offer.reward}
        />
      </li>
    ))}
  </ul>
  </>
  );
};

export default OffersOnly;