import axios from "axios";


export const getRevlumOffers = async (country) => {
    const { data } = await axios.get('api/revlumoffers', {
        headers: {
            "Content-Type": 'application/json'
        },
        params: {
            country: country
        }
    });
    return data;
};