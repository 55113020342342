import React from 'react'
import './topOffer.css'

/* eslint-disable react/prop-types */
// eslint-disable-next-line react/prop-types
const TopOffer = ({
    // eslint-disable-next-line react/prop-types
    title_text,
    center_text,
    image_game,
    image_device,
    image_Trophy,
    image_coin,
    link,
    reward_payout
  }) => {
    return (
      <div className="carousel-container">
        <div className="card">
          <div className="first">
            <div className="gameLogo">
              <img src={image_game} alt="" width={100} />
            </div>
            <div className="android">
              <img src={image_device} alt="" width={30} />
            </div>
          </div>
          <div className="second">
            <div className="heading">
              <h1 className="poppins-bold">{title_text}</h1>
            </div>
            <div className="level">
              <p className="poppins-semibold">{center_text}</p>
            </div>
            {/*
            <div>
              <button>
                <img src={image_Trophy} alt="" width={20} />{" "}
                <span className="poppins-semibold">Multi Reward</span>
              </button>
    </div> */}
          </div>
          <a className="link" href={link}>
          <div className="third">
            <div>
              <p className="poppins-bold">+{reward_payout}</p>
            </div>
          </div>
          </a>
        </div>
      </div>
    );
  };
  
  export default TopOffer;